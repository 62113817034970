function aboutAnimation() {
        let aboutItems = document.querySelectorAll('.about-item');
        document.querySelectorAll('.advantages-item').forEach(item=>{
         item.classList.add('fadePC');
        });
        document.querySelectorAll('.prices-item').forEach(item=>{
         item.classList.add('fadePC');
        });
        document.querySelectorAll('.feedback-item').forEach(item=>{
         item.classList.add('fadePC');
        });
        aboutItems.forEach(item=>{
         item.classList.add('fadePC');
        })
        fadeInAbout(document.querySelectorAll('.fadePC'));
}
function fadeInAbout(elements) {
    elements.forEach(element=>{
        let aboutObserver = new IntersectionObserver(entries=>{
            entries.forEach(entry=>{

                if(entry.isIntersecting){
                   if(window.innerWidth>475) {
                    setTimeout( function() {
                        entry.target.classList.add('slideRight');
                       },entry.target.dataset.delay);
                   } 
                   else {
                    entry.target.classList.add('slideRight');
                   }
                }
            })
        },{rootMargin:'-100px'})
        aboutObserver.observe(element);
    })
}
module.exports = aboutAnimation;