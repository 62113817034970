document.addEventListener('DOMContentLoaded',function(){
    const greetAnimation = require('./modules/greetAnimation');
    const politics = require('./modules/politics');
    const layoutFunc = require('./modules/layout');
    const typing = require('./modules/typingAnimation');
    const burger = require('./modules/hamburger');
    const formAjax = require('./modules/formAjax');
    const aboutAnimation = require('./modules/aboutAnimation');
    const fadeInMobile = require('./modules/fadeInMobile');
    // const calcQuestionItem = require('./modules/questions');
    const getMask = require('./modules/mask');
    ////////////////
    ///
    ////
    // greetAnimation(800);
    formAjax();
    layoutFunc();
    typing();
    burger();
    
    // calcQuestionItem();
    getMask();
    politics();
    if(window.screen.width> 475) {
        aboutAnimation();
    }
    if (window.screen.width<475) {
        fadeInMobile();
    }
});




  